import React, { Component } from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero"

class SuccessStories extends Component {
  reveal () {
    const ScrollReveal = require('scrollreveal').default

    ScrollReveal({
      viewFactor: 0.4,
      distance: '100px',
      duration: 1000
    });

    ScrollReveal().reveal('.sr-1', {
      delay: 0
    });

    ScrollReveal().reveal('.sr-r', {
      origin: 'right'
    });
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.reveal();
    }
  }

  render () {
    return (
      <Layout>
        <SEO title="GDPR и ISO 27001" description="GDPR и ISO 27001 — развивайте бизнес, используя автоматизацию многоканального маркетинга, основанную на данных. Побеждайте конкурентов!" />
        <Hero title="Наша компания полностью отвечает требованиям GDPR и имеет сертификацию ISO 27001." button="false" anim="false" gdpr="yes" classes="heroes--full has-text-centered" />

        <div className="container container--pad pad-t-2 pad-b-6 mar-t-6 mar-b-6">
          <div className="columns">
            <div className="column" />
            <div className="column is-two-thirds">
              <div className="columns is-centered">
                <div className="post__content content column">
                  <h3>GDPR и ExpertSender</h3>
                  <p>Общее положение о защите данных (GDPR) — это европейский закон о конфиденциальности, который вступил в силу 25 мая 2018 года. GDPR регулирует, как отдельные лица и организации могут собирать, использовать и хранить личные данные, что влияет на то, как наши клиенты используют платформу ExpertSender.</p>
                  <h4>К кому применимы требования GDPR?</h4>
                  <p id="dpo">GDPR применяется ко ВСЕМ организациям, которые собирают и обрабатывают личные данные лиц, проживающих на территории ЕС независимо от физического местонахождения компании. Это означает, что если у вас есть клиенты из ЕС, но ваш бизнес находится не на территории ЕС, вам все равно нужно будет соблюдать новые правила. Вы можете прочитать более подробный обзор того, <a href="https://expertsender.com/blog/does-gdpr-apply-to-you/" target="_blank" rel="noopener noreferrer">как GDPR к вашей деятельности здесь</a>.</p>
                  <h4>Наш менеджер по защите данных</h4>
                  <p class="mar-b-3">Если вы хотите знать, обрабатываем ли мы ваши личные данные, и реализовывать свои права, свяжитесь с нашим сотрудником по защите данных (Михал Кидонь) по этому адресу электронной почты: dpo@expertsender.com</p>
                  <h3>ISO 27001 и ExpertSender</h3>
                  <p>Компания ExpertSender решила получить сертификат ISO 27001, чтобы быть готовой к управлению рисками, связанными с обеспечением безопасности информационных активов (включая данные клиентов), которыми она управляет. Наша организация дает клиентам уверенность в том, что она применяет международные стандарты в активном управлении бизнес-данными и информационной безопасностью</p>
                  <h4>Что такое ISO 27001?</h4>
                  <p>ISO 27001 / IEC 27001 — это набор стандартов, которые помогают организациям защищать свои информационные активы (это включает в себя как информационные системы, так и внутренние бизнес-процессы). Эти международные стандарты описывают требования к системе управления информацией, которая обеспечивает информационную безопасность и управленческий контроль, а также предоставляет набор конкретных требований, которые организации обязаны выполнять.</p>
                  <p>Сертификация ISO / IEC 27001 свидетельствует о том, что организация определила лучшие процессы обеспечения информационной безопасности и внедрила их.</p>
                  <p><strong>Организации обязаны выполнять следующие требования:</strong></p>
                  <ul>
                    <li>систематическое изучение рисков информационной безопасности организации с учетом угроз, уязвимостей и потенциального воздействия;</li>
                    <li>разработку и внедрение согласованного и всеобъемлющего набора средств контроля информационной безопасности и/или другие формы управления рисками (например, избежание риска или передача риска) для устранения рисков, которые считаются неприемлемыми;</li>
                    <li>внедрение комплексной системы управления, которая непрерывно контролирует соответствие применимых средств обеспечения информационной текущим потребностям организации.</li>
                  </ul>
                  <h4>Что ISO 27001 значит для наших клиентов?</h4>
                  <strong>Став клиентом ExpertSender, вы можете быть уверены, что работаете с организацией, которая:</strong>
                  <ul>
                    <li>Защищает себя от кибератак и принимает необходимые меры, чтобы защитить вас.</li>
                    <li>Внедрила адекватную и соразмерную систему безопасности, которая помогает защитить данные и создавать кампании, соответствующие стандартам GDPR.</li>
                    <li>Проводит регулярные проверки и внутренние аудиты системы управления информационной безопасностью (ISMS) для улучшения ее работы.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="column" />
          </div>
          
          <div className="columns is-centered">
            <div className="post__contentWrap column">
              <hr className="mar-b-3" />
              <a href="/files/IEC-27001-256763-2018-AIS-POL-UKAS-2-en-US-20210803-20210805081118.pdf" class="arrow-link arrow-link--dl arrow-link--pdf mar-b-1" target="_blank" rel="noopener noreferrer">
                ISO/IEC 27001:2013 EN
              </a>
              <br />
              <a href="/files/IEC-27001-256763-2018-AIS-POL-UKAS-2-pl-PL-20210803-20210805081130.pdf" class="arrow-link arrow-link--dl arrow-link--pdf mar-b-1" target="_blank" rel="noopener noreferrer">
                ISO/IEC 27001:2013 PL
              </a>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SuccessStories
